import * as React from 'react'
import Layout from '../components/layout'
import { Link, graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import TruncateMarkup from 'react-truncate-markup'
//import Truncate from 'react-truncate'
import parse from 'html-react-parser'
import { ChevronRightIcon } from '@heroicons/react/outline'
import SEO from '../components/seo'

const Hardware = ({ data }) => {
    if (!data) return null
    const prodotti = data.allPrismicHardware

    return (
        <Layout>
          <SEO title="Hardware per call center" description="Thelgo mette a disposizione numerosi strumenti per il call center, gateway sms, gateway isdn, gateway primari oltre a telefoni IP e cuffie professionali" />
            <section className="px-6 py-12">
                <div className="container mx-auto">
                    <div>
                        <span className="tag">Hardware</span>
                        <h1 className="text-5xl text-gray-800 mb-1">Soluzioni Hardware</h1>
                        <h2 className="text-5xl text-persian-green-500 mb-6">Strumenti per sfruttare al massimo Thelgo</h2>
                    </div>

                    <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-12">
                        {prodotti.nodes.map((prodotto, index) => {
                            const image = getImage(prodotto.data.immagine.localFile)
                            return (
                                <div key={index}>
                                    <Link to={prodotto.uid} title={prodotto.data.titolo.text}><GatsbyImage image={image}  className="h-56" alt={prodotto.data.titolo.text} /></Link>
                                    <h3 className='text-xl font-bold mb-2 mt-4'>{prodotto.data.titolo.text}</h3>
                                    <TruncateMarkup lines={4} className="mt-4 mb-4 block">
                                        <div>
                                        {parse(prodotto.data.descrizione.html)}
                                        </div>
                                    </TruncateMarkup>
                                    <Link to={prodotto.uid} title={prodotto.data.titolo.text} className="mt-4 inline-flex items-center text-persian-green-500 text-sm hover:text-persian-green-600 transition-colors">Più informazioni <ChevronRightIcon className="w-4 h-4" /></Link>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Hardware

export const query = graphql`
  {
    allPrismicHardware(sort: {fields: uid, order: ASC}) {
      nodes {
        uid
        data {
          titolo {
            text
          }
          immagine {
            localFile {
              childImageSharp {
                gatsbyImageData(
                    placeholder: BLURRED
                    transformOptions: {cropFocus: CENTER, fit: COVER}
                  )
              }
            }
          }
          descrizione {
            html
          }
        }
      }
    }
  }
`